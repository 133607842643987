<template>
  <div class="cv">
    <div class="container">
      <div class="cv_container">
        <div class="header">
          <div class="full-name">
            <span class="first-name">Denis </span>
            <span class="last-name">Iamilov</span>
          </div>
          <div class="position">
            <p><span class="position">Back-End Developer</span></p>
          </div>
          <div class="pt-3">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 pb-4">
                <div class="contact-info">Contact information</div>
                <div>
                  <span class="email">Email: </span>
                  <span class="email-val">info@amunere.com</span></div>
                <div>
                  <span class="phone">Phone: </span>
                  <span class="phone-val">+971 54 377 42 88</span></div>
                <div>
                  <span class="local">Location: </span>
                  <span class="local-val">Somewhere on the Planet</span></div>
                <div>
                  <span class="linkedin">Linkedin: </span>
                  <span class="linkedin-val">https://www.linkedin.com/in/amunere</span></div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 pb-4">
                <div class="about-me">About Me</div>
                <p class="pt-2 pb-2">
                  <span>I have 4+ years of experience in web applications </span>
                  <span>development using Python/Django Rest Framework Flask, </span>
                  <span>NodeJS ExpressJS, JavaScript/VueJS, optimization SQL </span>
                  <span>queries using ORM. Deploy using Linux, Docker, Nginx, Apache</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="details">
          <div class="experience">
            <div class="experience__title">Experience</div>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Self-Employed (Freelance)</div>
                <div class="addr">Russia</div>
                <div class="duration">2018 - present</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Back-End Development</div>
                <div class="desc">Using Django Flask, NodeJS ExpressJS,
                  JavaScript/VueJS, optimization SQL queries using ORM.
                  Deploy using Linux, Docker, Nginx</div>
              </div>
            </div>
          </div>
          <div class="education">
            <div class="education__title">Education</div>
            <div class="row pb-4">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Humanitarian and Technical University</div>
                <div class="addr">Russia</div>
                <div class="duration">Apr 2022 - Sep 2022</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 pb-4">
                <div class="name">Programming Engineer</div>
                <div class="desc">University Degree in Computer Programming.
                  Programming and Database administration</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Kama State Engineering and Economic Academy</div>
                <div class="addr">Russia</div>
                <div class="duration">Sep 2006 - Jul 2010</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 pb-4">
                <div class="name">Environmental Engineering</div>
                <div class="desc">University Degree in Environmental
                  Protection and Rational use of Natural Resources</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">SkillFactory</div>
                <div class="addr">Russia</div>
                <div class="duration">Nov 2022 - Jul 2023</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6 pb-4">
                <div class="name">Software Development</div>
                <div class="desc">Python Developer</div>
              </div>
            </div>
          </div>
          <div class="skills">
            <div class="skills__title">Skills</div>
            <div class="row skills__item pb-4">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Python3</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">DRF &amp; Flask, FastAPI</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">JavaScript/VueJS</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">PostgreSQL, MongoDB</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">HTML5 &amp; CSS3</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Linux Server</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">NodeJS/ExpressJS</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="name">Git</div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              </div>
            </div>
          </div>
          <div class="stack">
            <div class="stack__title">Language Proficiency</div>
            <div class="row">
              <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                  <div class="">Russian native</div>
                  <div class="">English pre B1</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
